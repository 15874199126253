<template>
  <div>
    <Menu :menu="accessMenu" prefix="" />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Menu from '@/components/atoms/Menu.vue'
import { ADDEVENT_ADMIN_ROUTES } from '@/constants/routing'
import { mapGetters } from 'vuex'

export default {
  components: {
    Menu,
  },
  data() {
    return {
      menu: [
        { title: 'Страницы', link: ADDEVENT_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
        {
          title: 'SEO-шаблоны',
          link: ADDEVENT_ADMIN_ROUTES.CONTENT.SEO_TEMPLATES.INDEX,
        },
        {
          title: 'Общее',
          link: ADDEVENT_ADMIN_ROUTES.CONTENT.COMMON.INDEX,
        },
        {
          title: 'С нами работают',
          link: ADDEVENT_ADMIN_ROUTES.CONTENT.WORKING_WITH_US.INDEX,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      filterAccessMenuList: 'filterAccessMenuList',
    }),
    accessMenu() {
      return this.filterAccessMenuList(this.menu)
    },
  },
}
</script>
